import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CourseIcon from '../icons/CourseIcon';
import ToWatchCourseIcon from '../icons/ToWatchCourseIcon';
import WatchedCourseIcon from '../icons/WatchedCourseIcon';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';
import MaterialIcon from '../icons/MaterialIcon';
import BackButton from '../BackButton';
import DrawerLogo from '../layouts/DrawerLogo';
import Message from '../translation/Message';
import {
  courseResultsSelector,
  courseTypesSelector,
  currentCourseSelector, sessionResultsSelector,
} from '../../store/selectors';
import {
  getCourseUrl,
  getSessionUrl, getUserAreaPageUrl,
} from '../../routes/paths';
import { SimplifiedSession } from '../../types';
import { userAreaSelector } from '../../store/userAreaSlice';
import { currentGroupSelector } from '../../store/groupSlice';
import { userSelector } from '../../store/userSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBackArea: {
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      whiteSpace: 'normal',
      backgroundColor: theme.palette.grey[100],
    },
    drawerHeader: {
      paddingTop: '8px',
      display: 'block',
      '& > *': {
        padding: 'unset',
      },
    },
    drawerIcon: {
      minWidth: 'unset',
      paddingRight: theme.spacing(1),
    },
    drawerSessionIcon: {
      alignSelf: 'flex-start',
      minWidth: 'unset',
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
  })
);

const getCourseTypeIcon = (iconName: string) => {
  if (iconName === 'Default') {
    return <CourseIcon color="primary" />;
  } else {
    return (
      <MaterialIcon
        fontSize="small"
        iconName={iconName.split('Icon')[0]}
        color="primary"
      />
    );
  }
};

type SessionListItemInfo = {
  session: SimplifiedSession;
  disabled: boolean;
  passed: boolean;
  mandatoryToAnswer?: boolean;
  containsQuestions?: boolean;
};

const CourseDrawer = ({ drawerOpen, onClose }: { drawerOpen: boolean; onClose: () => void }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view

  const course = useSelector(currentCourseSelector);
  const courseTypes = useSelector(courseTypesSelector);
  const userArea = useSelector(userAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const courseResults = useSelector(courseResultsSelector);
  const sessionResults = useSelector(sessionResultsSelector);
  const user = useSelector(userSelector);
  
  const [backButtonPath, setBackButtonPath] = useState('');

  let currentSession = course
    ? course.sessions.find((s) => window.location.pathname.includes(s.id))
    : null;

  let courseType = courseTypes
    ? courseTypes.find((ct) => ct.courseType.id === course?.courseType)
    : null;
  
  useEffect(() => {
    if (userArea) {
      setBackButtonPath(getUserAreaPageUrl(userArea.id));
    } else if (currentGroup) {
      setBackButtonPath(getUserAreaPageUrl(currentGroup.id))
    } else if (currentSession && courseType) {
      setBackButtonPath(`/masterclasses/${courseType.courseType.name}`)
    } else if (courseType) {
      setBackButtonPath(`/masterclasses/${courseType.courseType.name}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, userArea, currentGroup, currentSession]);

  const sessionList = useMemo(() => {
    const orderSessionPassed = (
      simplifiedSession: SimplifiedSession | undefined
    ) => {
      if (course && courseResults[course.id] && simplifiedSession?.id) {
        const session = course.sessions.find(
          (s) => s.id === simplifiedSession.id
        );
        if (session) {
          const result = courseResults[course.id].sessionResults.find(
            (res) => res.sessionId === session.id
          );
          if (result) {
            const filterFreeText = result.answerResults.filter(
              (res) => res.answerType === 'FreeText'
            );
            const onlyFreeText =
              filterFreeText.length === result.answerResults.length;

            let passed: boolean;
            if (result.pass) {
              passed = result.pass;
            } else if (filterFreeText.length !== 0 && onlyFreeText) {
              passed = true;
            } else {
              const filterPoints = filterFreeText
                .map((res) => res.maxPoints)
                .reduce((sum, curr) => sum + curr, 0);

              const minPoints = session.minimumPoints
                ? session.minimumPoints
                : result.maxPoints;

              passed =
                minPoints - filterPoints <= result.totalPoints - filterPoints;
            }
            return passed;
          }
        }
      }
    };
    if (!course || !courseResults[course.id]) {
      return null;
    }
    const grouped = course.sessionOrder?.map((simplifiedSession, index) => {
      let passed: boolean;
      let disabled: boolean;

      const prevPassed = orderSessionPassed(course.sessionOrder?.[index - 1]);
      const currPassed = orderSessionPassed(course.sessionOrder?.[index]);

      const session = course.sessions.find(
        (s) => s.id === simplifiedSession.id
      );

      let mandatoryToAnswer = session?.mandatoryToAnswer ?? true;
      let containsQuestions =
        session?.questionAnswers && session.questionAnswers.length > 0;

      if (prevPassed !== undefined) {
        disabled = !prevPassed && mandatoryToAnswer;
        passed = currPassed ?? false;
      } else {
        disabled = false;
        passed = currPassed ?? false;
      }

      return {
        disabled,
        passed,
        session: simplifiedSession,
        mandatoryToAnswer,
        containsQuestions,
      };
    });
    return grouped ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, courseResults, sessionResults, user]);

  const handleSessionClick = () => {
    if (isMobile) {
      onClose(); // Close drawer on mobile
    }
  };
  
  return (
    <nav className={classes.drawerContent}>
      <div>
        <DrawerLogo drawerOpen={drawerOpen} />
        {courseType && backButtonPath && (
          <Box className={classes.drawerBackArea}>
            <BackButton messageId="course.back" linkTo={backButtonPath} />
          </Box>
        )}
        <Divider />
        <List disablePadding>
          <ListItem
            button
            component={Link}
            to={getCourseUrl(course?.slug || '')}
            className={classes.drawerHeader}
            style={
              currentSession
                ? {}
                : { backgroundColor: theme.palette.secondary.main }
            }
            onClick={handleSessionClick}
          >
            <ListItem>
              <ListItemIcon className={classes.drawerIcon} color="primary">
                {getCourseTypeIcon(courseType?.courseType.icon || 'Default')}
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={
                  <Typography variant="button" color="primary">
                    {courseType?.courseType.name || (
                      <Message id="course.course" />
                    )}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle2">{course?.name}</Typography>
                }
              />
            </ListItem>
          </ListItem>
          <Divider />

          {course &&
            sessionList?.map(
              ({
                disabled,
                session,
                passed,
                mandatoryToAnswer,
                containsQuestions,
              }: SessionListItemInfo) => {
                return (
                  <ListItem
                    button
                    disabled={disabled && !mandatoryToAnswer}
                    component={Link}
                    to={`${getSessionUrl(course.slug, session.id)}`}
                    key={session.id}
                    style={
                      currentSession && session.id === currentSession.id
                        ? { backgroundColor: theme.palette.secondary.main }
                        : {}
                    }
                    onClick={handleSessionClick}
                  >
                    <ListItemIcon className={classes.drawerSessionIcon}>
                      {(!mandatoryToAnswer || passed) && (
                        <WatchedCourseIcon color="primary" />
                      )}
                      {!passed && mandatoryToAnswer && <ToWatchCourseIcon />}
                      {containsQuestions && <QuestionMarkIcon />}
                    </ListItemIcon>
                    <ListItemText primary={session.name} />
                  </ListItem>
                );
              }
            )}
          {course && course?.sessions.length === 1 && !sessionList && (
            <ListItem
              button
              disabled={false}
              component={Link}
              to={`${getSessionUrl(course.slug, course.sessions[0].id)}`}
              key={course.sessions[0].id}
              style={
                currentSession && course.sessions[0].id === currentSession.id
                  ? { backgroundColor: theme.palette.secondary.main }
                  : {}
              }
            >
              <ListItemIcon className={classes.drawerSessionIcon}>
                <WatchedCourseIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={course.sessions[0].name} />
            </ListItem>
          )}
        </List>
      </div>
    </nav>
  );
};

export default CourseDrawer;
